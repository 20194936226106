
.topToolBar{
    padding: 0.2rem 2rem;    
    background: #89c2fefa;
}
.topToolBar .logo-container{
    padding: 0.2rem 0rem;
    width: 100%;
    height: 100%;
}
.topToolBar .logo-container .logo{
    max-height: 100%;
    width: 100%;
    padding: 0rem;
}
.topToolBar .user-menu{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
}
.topToolBar .user-menu label{
    margin-left: 0.4rem;
    font-size: 1.2rem;
}
.topToolBar .user-menu .avatar{
    width: 40px;
    height: 40px;
    padding: 0.5rem;
    font-size: 1.2rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

/* -- sm - breakpoint -- */
@media (max-width:767px){
    .topToolBar .user-menu label{
        display: none;
    }
    .topToolBar .user-menu .avatar{
        width: 30px;
        height: 30px; 
    }
}