/*Animations*/
.fadeIn {
    animation: 0.5s fadeIn forwards;
    width: 100%;
}  
.fadeOut {
    animation: 0.5s fadeOut forwards;
    width: 100%;
}
.OpIn{
    opacity: 1;
    transition: 1s;
    width: 100%;
    /*animation: 1s OpIn forwards;*/
}
.OpOut{
    opacity: 0;
    transition: 1s;
    width: 100%;
    /*animation: 1s OpOut forwards;*/
    
}
  
@keyframes fadeIn {
    from {
      transform: translate(-100vw, 0);
    }
    to {
      transform: translate(0px, 0px);
    }
}
  
@keyframes fadeOut {
    from {
      transform: translate(0px, 0px);
    }
    to {
      transform: translate(-100vw, 0);
    }
}
  
@keyframes OpIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}
  
@keyframes OpOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
}