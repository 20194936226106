.main-container{
    width: 90%;
    height: 90vh;
    margin: 5% auto;
    /*display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;*/
}
.main-container .main-content{
    padding: 24px 0;
    background-color: #ffffff;
}

/* -- xl - breakpoint -- */
@media (min-width:1200px){}
/* -- lg - breakpoint -- */
@media (max-width:1199px) and (min-width:992px){}
/* -- md - breakpoint -- */
@media (max-width:991px) and (min-width:768px){}
/* -- sm - breakpoint -- */
@media (max-width:767px){
    .main-container{
        padding: 0rem 1rem;
    }
}